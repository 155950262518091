@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-link-contact {
  color: #126CB3;
  border: 2px solid #126CB3;
  padding: 4px 8px;
  border-radius: 4px;
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s;
}

.nav-link-contact:hover {
  background-color: #126CB3;
  color: white;
}

.nav-link {
  font-family: 'Bodoni Moda', serif;
}

.my-font{
  font-family: 'Bodoni Moda', serif;
}

.bg-custom-bg {
  background-image: url('https://cdn.b12.io/client_media/PBPLLzkO/e9b029a0-c6cc-11ed-a764-0242ac110002-jpg-hero_image.jpeg');
  background-repeat: no-repeat;
  background-position: right;
}
